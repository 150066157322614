import { useIntl } from 'react-intl'
import { MenuItem } from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { MegaMenu } from './MegaMenu'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/dashboard' />

      <MenuInnerWithSub
        title='Actions'
        to='/actions'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuInnerWithSub
          title='Company Actions'
          to='/actions/company'
          hasArrow={true}
          icon='brifecase-tick'
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/actions/company/list' title='Company List' hasBullet={true} />

        </MenuInnerWithSub>
        <MenuInnerWithSub
          title='Operator Actions'
          to='/actions/operator'
          hasArrow={true}
          icon='abstract-31'
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/actions/operator/list' title='Operator List' hasBullet={true} />

        </MenuInnerWithSub>
        <MenuInnerWithSub
          title='Provider Actions'
          to='/actions/provider'
          hasArrow={true}
          icon='abstract-17'
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/actions/provider/list' title='Provider List' hasBullet={true} />

        </MenuInnerWithSub>
        <MenuInnerWithSub
          title='Game Actions'
          to='/actions/game'
          hasArrow={true}
          icon='abstract-17'
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/actions/game/list' title='Game List' hasBullet={true} />
          
        </MenuInnerWithSub>
        <MenuInnerWithSub
          title='Service Actions'
          to='/actions/service'
          hasArrow={true}
          icon='abstract-9'
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/actions/service/countries' title='Countries' hasBullet={true} />
          <MenuItem to='/actions/service/currencies' title='Currencies' hasBullet={true} />
          <MenuItem to='/actions/service/languages' title='Languages' hasBullet={true} />
        </MenuInnerWithSub>
        {/* <MenuInnerWithSub
          title='Integration Actions'
          to='/actions/integrations'
          hasArrow={true}
          icon='abstract-13'
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/actions/integrations/list' title='Integration' hasBullet={true} />
        </MenuInnerWithSub> */}
      </MenuInnerWithSub>

      <MenuInnerWithSub title='Management' to='/management' menuPlacement='bottom-start' menuTrigger='click'>
        {/* PAGES */}
        <MenuInnerWithSub
          title='Reports'
          to='/management/reports'
          hasArrow={true}
          icon='abstract-16'
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/management/reports/general' title='General Reports' hasBullet={true} />
          <MenuItem to='/management/reports/game' title='Game Reports' hasBullet={true} />
          <MenuItem to='/management/reports/operator' title='Operator Reports' hasBullet={true} />
          <MenuItem to='/management/reports/company' title='Company Reports' hasBullet={true} />
          <MenuItem to='/management/reports/provider' title='Provider Reports' hasBullet={true} />
          
        </MenuInnerWithSub>
        <MenuInnerWithSub
          title='Management'
          to='/management/management'
          hasArrow={true}
          icon='abstract-28'
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/management/management/user-list' title='User List' hasBullet={true} />
          <MenuItem to='/management/management/user-list' title='xxx' hasBullet={true} />
        </MenuInnerWithSub>
        <MenuInnerWithSub
          title='Logs'
          to='/management/logs'
          hasArrow={true}
          icon='abstract-3'
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/management/logs/user-logs' title='User Logs' hasBullet={true} />
          <MenuItem to='/management/logs/user-logs' title='xxx Logs' hasBullet={true} />
        </MenuInnerWithSub>

      </MenuInnerWithSub>

      {/* <MenuInnerWithSub
        isMega={true}
        title='Mega menu'
        to='/mega-menu'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MegaMenu />
      </MenuInnerWithSub> */}
    </>
  )
}
