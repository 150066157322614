import React, {ChangeEvent, useEffect, useState} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import Network from '../../../../helpers/Network'
import {ServiceProps} from '../../../../helpers/_models'

interface DataItems {
  status?: string
  name?: string
  short_name: string
  image: string
  provider_id: any
}
type ModalProps = {
  addGamesValue: DataItems
  setAddGamesValue: any
}

const GameModal: React.FC<ModalProps> = ({addGamesValue, setAddGamesValue}) => {
  const [provider, setProvider] = useState<ServiceProps>()
  const [imageUrl, setImageUrl] = useState('')
  const [image, setImage] = useState(null || undefined || {})

  const mountProvider = async () => {
    await Network.postData('/provider/all', {
      order_key: 'short_name',
      order_by: 'asc',
    })
      .then((res) => {
        console.log(res)
        setProvider(res)
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    mountProvider()
  }, [])

  return (
    <div className='d-flex flex-column align-items-center w-100 p-4'>
      <div className='position-relative w-100 m-4'>
        <label htmlFor='gamesName' className='mb-1 ms-1'>
          Game Name
        </label>
        <input
          type='text'
          id='gamesName'
          className='form-control form-control-sm form-control-solid'
          name='name'
          value={addGamesValue.name}
          onChange={(e) => setAddGamesValue({...addGamesValue, [e.target.name]: e.target.value})}
          placeholder='Type Name'
        />
      </div>
      <div className='position-relative w-100 m-4'>
        <label htmlFor='short_name' className='mb-1 ms-1'>
          Game Short Name
        </label>
        <input
          type='text'
          id='short_name'
          className='form-control form-control-sm form-control-solid '
          name='short_name'
          value={addGamesValue.short_name}
          onChange={(e) => setAddGamesValue({...addGamesValue, [e.target.name]: e.target.value})}
          placeholder='Type Name'
        />
      </div>
      <div className='position-relative w-100 m-4'>
        <label htmlFor='image' className='mb-1 ms-1'>
          Geçici Image URL
        </label>
        <input
          type='text'
          id='image'
          className='form-control form-control-sm form-control-solid '
          name='image'
          value={addGamesValue.image}
          onChange={(e) => setAddGamesValue({...addGamesValue, [e.target.name]: e.target.value})}
          placeholder='Type Name'
        />
      </div>

      <div className='position-relative w-100 m-4'>
        <label htmlFor='selectProvider' className='mb-1 ms-1'>
          Select provider
        </label>
        <select
          className='form-select form-select-solid'
          id='selectProvider'
          aria-label='Select'
          defaultValue={'0'}
          onChange={(e) => {
            setAddGamesValue({...addGamesValue, provider_id: Number(e.target.value)})
          }}
        >
          <option style={{display: 'none'}} value='0' disabled>
            Select
          </option>
          {provider?.data.map((item: any) => {
            return (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            )
          })}
        </select>
      </div>

      <div className='position-relative w-100 m-4'>
        <div className='image-input position-relative image-input-outline' id='kt_image_1'>
          {imageUrl ? (
            <div
              className='image-input-wrapper'
              style={{backgroundImage: `url(${imageUrl})`}}
            ></div>
          ) : (
            <div
              className='image-input-wrapper'
              style={{backgroundImage: `url(${addGamesValue?.image})`}}
            ></div>
          )}

          <label
            className='btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow '
            data-action='change'
            data-toggle='tooltip'
            title=''
            style={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              backgroundColor: '#ffffff7a',
              width: '25px',
              height: '25px',
            }}
            data-original-title='Change avatar'
          >
            <i className='fa fa-pen icon-sm text-warning'></i>
            <input
              type='file'
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (e.target.files && e.target.files[0]) {
                  setImageUrl(URL.createObjectURL(e.target.files[0]))
                  setImage(e.target.files[0])
                }
              }}
              style={{display: 'none'}}
              name='profile_avatar'
              accept='.png, .jpg, .jpeg'
            />
            <input type='hidden' className='hidden' name='profile_avatar_remove' />
          </label>

          <span
            className='btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow'
            data-action='cancel'
            data-toggle='tooltip'
            title='Cancel avatar'
          >
            <i className='ki ki-bold-close icon-xs text-muted'></i>
          </span>
        </div>
      </div>
    </div>
  )
}

export default GameModal
