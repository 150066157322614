import React, {useEffect, useState} from 'react'
import DropdownSearch from '../../patterns/DropdownSearch'
import {KTIcon} from '../../../_metronic/helpers'
import Network from '../../helpers/Network'
import OperatorTable from '../../modules/actions/operator/operatorlist/TablesWidget'
import ProviderTable from '../../modules/actions/provider/TablesWidget'
import GameTable from '../../modules/actions/game/gamelist/TablesWidget'
import CompanyTable from '../../modules/actions/company/TablesWidget'

const SearchPage: React.FC = () => {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState('all')
  const [search, setSearch] = useState('')
  const [url, setUrl] = useState('')

  const filterHandler = async (uri: string, search: string = '') => {
    // eslint-disable-next-line no-useless-concat
    await Network.getData(`admin/search/${uri}` + `${search && `?name=${search}`}`).then((res) => {
      setData(res)
      setSearch('')
    })
  }

  useEffect(() => {
    if (url) {
      filterHandler(url)
    }
  }, [url])

  const label =
    filter === 'all'
      ? 'Search all pages'
      : filter === 'players'
      ? 'Search on Players Page'
      : filter === 'companies'
      ? 'Search on Players Page'
      : filter === 'games'
      ? 'Search on Games Page'
      : filter === 'operators'
      ? 'Search on Operators Page'
      : filter === 'provider'
      ? 'Search on Providers Page'
      : ''

  return (
    <>
      <div className='card mb-10 p-2'>
        <div className='p-4 d-flex w-100 align-items-end justify-content-between'>
          <div className='form-group flex-1' style={{width: '100%', flex: 1}}>
            <label className='fw-bold mb-2'>{label}</label>
            <div className='input-icon input-icon-right'>
              <input
                type='text'
                className='form-control p-2'
                placeholder='Search...'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span>
                <i className='flaticon2-search-1 icon-md'></i>
              </span>
            </div>
          </div>
          <div className='ms-1'>
            <div className='card-toolbar'>
              {/* begin::Menu */}
              <button
                type='button'
                className='btn me-1 btn-sm btn-icon btn-color-primary btn-active-light-primary btn-bg-light'
                style={{minWidth: '100px'}}
                // disabled={search.length < 3}
                onClick={() => {
                  filterHandler(url, search)
                }}
              >
                <KTIcon iconName='filter-search' className='fs-2' />{' '}
                <span className='ms-2 fs-3'>Ara</span>
              </button>
              <button
                type='button'
                className='btn me-1 btn-sm btn-icon btn-color-primary btn-active-light-primary btn-bg-light'
                style={{minWidth: '100px'}}
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <KTIcon iconName='category' className='fs-2' />{' '}
                <span className='ms-2 fs-3'>Filters</span>
              </button>
              <DropdownSearch
                optionList={[
                  {name: 'Players', value: 'players'},
                  {name: 'Games', value: 'games'},
                  {name: 'Companies', value: 'companies'},
                  {name: 'Operators', value: 'operators'},
                  {name: 'Providers', value: 'provider'},
                ]}
                setFilter={setFilter}
                filter={filter}
                search={search}
                setUrl={setUrl}
              />
              {/* end::Menu */}
            </div>
          </div>
        </div>
      </div>
      {url === 'operators' && (
        <OperatorTable
          mountData={() => filterHandler(url)}
          setData={setData}
          className='mb-5 mb-xl-8'
          data={data}
          tablehead={[
            {name: 'Name', sortName: 'name', isSort: false},
            {name: 'Status', sortName: 'status', isSort: false},
            {name: 'Actions', sortName: '', isSort: false},
          ]}
        />
      )}
      {url === 'provider' && (
        <ProviderTable
          mountData={() => filterHandler(url)}
          setData={setData}
          className='mb-5 mb-xl-8'
          data={data}
          tablehead={[
            {name: 'Name', sortName: 'name', isSort: true},
            {name: 'Short Name', sortName: 'short_name', isSort: true},
            {name: 'slug', sortName: 'slug', isSort: true},
            {name: 'Status', sortName: 'status', isSort: false},
            {name: 'Actions', sortName: '', isSort: false},
          ]}
        />
      )}
      {url === 'games' && (
        <GameTable
          mountData={() => filterHandler(url)}
          setData={setData}
          className='mb-5 mb-xl-8'
          data={data}
          tablehead={[
            {name: 'Name', sortName: 'name', isSort: true},
            {name: 'Status', sortName: 'status', isSort: false},
            {name: 'Actions', sortName: '', isSort: false},
          ]}
        />
      )}
      {url === 'companies' && (
        <CompanyTable
          mountData={() => filterHandler(url)}
          setData={setData}
          className='mb-5 mb-xl-8'
          data={data}
          tablehead={[
            {name: 'Name', sortName: 'name', isSort: true},
            {name: 'Public Name', sortName: 'public_name', isSort: true},
            {name: 'Status', sortName: 'status', isSort: false},
            {name: 'Actions', sortName: '', isSort: false},
          ]}
        />
      )}
    </>
  )
}

export default SearchPage
