const SvgData = {
  arrow: (rotate = "rotate(0deg)") => {
    return (
      <svg
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        stroke='currentColor'
        strokeWidth={1}
        viewBox='0 0 24 24'
        style={{transform: rotate}}
      >
        <path d='M12.529 14.676l4.288-4.288c0.135-0.136 0.219-0.323 0.219-0.529 0-0.414-0.335-0.749-0.749-0.75h-0c-0.001-0-0.002-0-0.004-0-0.205 0-0.391 0.083-0.526 0.218l-3.758 3.756-3.758-3.756c-0.136-0.136-0.323-0.22-0.53-0.22-0.414 0-0.749 0.335-0.749 0.749 0 0.207 0.084 0.394 0.219 0.529l4.287 4.288c0.136 0.136 0.323 0.219 0.53 0.219s0.394-0.084 0.53-0.219l-0 0z'></path>
      </svg>
    )
  },
  minus: () => {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        version='1.1'
        width={15}
        height={15}
        viewBox='0 0 256 256'
        xmlSpace='preserve'
      >
        <defs></defs>
        <g
          style={{
            stroke: 'none',
            strokeWidth: 0,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: 'none',
            fillRule: 'nonzero',
            opacity: 1,
          }}
          transform='translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)'
        >
          <path
            d='M 86.5 48.5 h -83 C 1.567 48.5 0 46.933 0 45 s 1.567 -3.5 3.5 -3.5 h 83 c 1.933 0 3.5 1.567 3.5 3.5 S 88.433 48.5 86.5 48.5 z'
            style={{
              stroke: 'none',
              strokeWidth: 1,
              strokeDasharray: 'none',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: 10,
              fill: 'rgb(29,29,27)',
              fillRule: 'nonzero',
              opacity: 1,
            }}
            transform=' matrix(1 0 0 1 0 0) '
            strokeLinecap='round'
          />
          <path
            d='M 86.5 48.5 h -83 C 1.567 48.5 0 46.933 0 45 s 1.567 -3.5 3.5 -3.5 h 83 c 1.933 0 3.5 1.567 3.5 3.5 S 88.433 48.5 86.5 48.5 z'
            style={{
              stroke: 'none',
              strokeWidth: 1,
              strokeDasharray: 'none',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: 10,
              fill: 'rgb(29,29,27)',
              fillRule: 'nonzero',
              opacity: 1,
            }}
            transform=' matrix(1 0 0 1 0 0) '
            strokeLinecap='round'
          />
        </g>
      </svg>
    )
  },
}

export default SvgData
