const createUrl = (params: object) => {
  const paramaterList: string[] = []

  for (const key in params) {
    if (params[key]) {
      paramaterList.push(`${key}=${params[key]}`)
    }
  }

  if (paramaterList.length > 0) {
    return `?${paramaterList.join('&')}`
  } else {
    return ''
  }
}

export default createUrl
