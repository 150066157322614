import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import Network from '../../../../helpers/Network'
import {ServiceProps} from '../../../../helpers/_models'

type SetStateFunction<T> = React.Dispatch<React.SetStateAction<T>>

interface DataItems {
  status?: string
  api_secret?: string
  api_key?: string
  api_endpoint?: string
  name?: string
  api_allowed_ips: any
  email: string
  password: string
  operator_name: string
}

type ModalProps = {
  addOperatorValue: DataItems
  setAddOperatorValue: any
  //   setAddOperatorValue: SetStateFunction<DataItems>
}

const OperatorModal: React.FC<ModalProps> = ({addOperatorValue, setAddOperatorValue}) => {
  const [apiAllowedIps, setApiAllowedIps] = useState('')
  const [company, setCompany] = useState<ServiceProps>()
  const [integration, setIntegration] = useState<ServiceProps>()
  const mountCompany = async () => {
    await Network.postData('/company/all', {
      order_key: 'id',
      order_by: 'asc',
    })
      .then((res) => {
        setCompany(res)
      })
      .catch((err) => console.log(err))
  }
  const mountIntegration = async () => {
    await Network.postData('/integration/all', {
      order_key: 'id',
      order_by: 'asc',
    })
      .then((res) => {
        setIntegration(res)
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    mountCompany()
    mountIntegration()
  }, [])

  return (
    <div className='d-flex flex-column align-items-center w-100 p-4'>
      <div className='position-relative w-100 m-4'>
        <label htmlFor='name' className='mb-1 ms-1'>
          Name
        </label>
        <input
          type='text'
          id='name'
          className='form-control form-control-sm form-control-solid'
          name='name'
          value={addOperatorValue.name}
          onChange={(e) =>
            setAddOperatorValue({...addOperatorValue, [e.target.name]: e.target.value})
          }
          placeholder='Type Name'
        />
      </div>
      <div className='position-relative w-100 m-4'>
        <label htmlFor='operatorName' className='mb-1 ms-1'>
          Operator Name
        </label>
        <input
          type='text'
          id='operatorName'
          className='form-control form-control-sm form-control-solid'
          name='operator_name'
          value={addOperatorValue.operator_name}
          onChange={(e) =>
            setAddOperatorValue({...addOperatorValue, [e.target.name]: e.target.value})
          }
          placeholder='Type Name'
        />
      </div>
      <div className='position-relative w-100 m-4'>
        <label htmlFor='operatorEmail' className='mb-1 ms-1'>
          Operator Email
        </label>
        <input
          type='text'
          id='operatorEmail'
          className='form-control form-control-sm form-control-solid'
          name='email'
          value={addOperatorValue.email}
          onChange={(e) =>
            setAddOperatorValue({...addOperatorValue, [e.target.name]: e.target.value})
          }
          placeholder='Type Email'
        />
      </div>
      <div className='position-relative w-100 m-4'>
        <label htmlFor='operatorPassword' className='mb-1 ms-1'>
          Operator Password
        </label>
        <input
          type='text'
          id='operatorPassword'
          className='form-control form-control-sm form-control-solid'
          name='password'
          value={addOperatorValue.password}
          onChange={(e) =>
            setAddOperatorValue({...addOperatorValue, [e.target.name]: e.target.value})
          }
          placeholder='Type Password'
        />
      </div>
      <div className='position-relative w-100 m-4'>
        <label htmlFor='api_endpoint' className='mb-1 ms-1'>
          Api Endpoint
        </label>
        <input
          type='text'
          id='api_endpoint'
          className='form-control form-control-sm form-control-solid '
          name='api_endpoint'
          value={addOperatorValue.api_endpoint}
          onChange={(e) =>
            setAddOperatorValue({...addOperatorValue, [e.target.name]: e.target.value})
          }
          placeholder='Type Name'
        />
      </div>

      <div className='position-relative w-100 m-4'>
        <label htmlFor='selectCompany' className='mb-1 ms-1'>
          Select Company
        </label>
        <select
          className='form-select form-select-solid'
          id='selectCompany'
          aria-label='Select'
          defaultValue={'0'}
          onChange={(e) => {
            setAddOperatorValue({...addOperatorValue, company_id: Number(e.target.value)})
          }}
        >
          <option style={{display: 'none'}} value='0' disabled>
            Select
          </option>
          {company?.data?.map((item: any) => {
            return (
              <option value={item.id} key={item.id}>
                {item.name} - {item.public_name}
              </option>
            )
          })}
        </select>
      </div>
      <div className='position-relative w-100 m-4'>
        <label htmlFor='selectIntegration' className='mb-1 ms-1'>
          Select Integration
        </label>
        <select
          className='form-select form-select-solid'
          id='selectIntegration'
          aria-label='Select'
          defaultValue={'0'}
          onChange={(e) => {
            setAddOperatorValue({...addOperatorValue, integration_id: Number(e.target.value)})
          }}
        >
          <option style={{display: 'none'}} value='0' disabled>
            Select
          </option>
          {integration?.data?.map((item: any) => {
            return (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            )
          })}
        </select>
      </div>
      <div className='position-relative d-flex flex-column w-100 m-4'>
        <label htmlFor='api_allowed_ips' className='mb-1 ms-1'>
          Api Allowed IPS
        </label>
        <div className='d-flex align-items-center'>
          <input
            type='text'
            id='api_allowed_ips'
            className='form-control form-control-sm form-control-solid w-75'
            name='api_allowed_ips'
            value={apiAllowedIps}
            onChange={(e) => {
              setApiAllowedIps(e.target.value)
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                const newArr = [...addOperatorValue.api_allowed_ips]
                newArr.push(apiAllowedIps)
                setAddOperatorValue({...addOperatorValue, api_allowed_ips: newArr})
                setApiAllowedIps('')
              }
            }}
            placeholder='Type Api Allowed IPS'
          />
          <button
            type='button'
            className='btn btn-danger clear-badges w-25'
            onClick={() => setAddOperatorValue({...addOperatorValue, api_allowed_ips: []})}
          >
            Clear All
          </button>
        </div>
        <div className='operator-badge-provider'>
          {addOperatorValue?.api_allowed_ips?.map(
            (
              item:
                | boolean
                | React.ReactChild
                | React.ReactFragment
                | React.ReactPortal
                | null
                | undefined,
              index: number
            ) => {
              return (
                <span className='operator-badge' key={index}>
                  <span>{item}</span>
                  <span
                    className='close-icon'
                    onClick={() => {
                      const newArr = addOperatorValue.api_allowed_ips.filter(
                        (_: any, i: number) => i !== index
                      )

                      setAddOperatorValue({...addOperatorValue, api_allowed_ips: newArr})
                    }}
                  >
                    <KTIcon iconName='trash' iconType='duotone' className='text-danger' />
                  </span>
                </span>
              )
            }
          )}
        </div>
      </div>
    </div>
  )
}

export default OperatorModal
