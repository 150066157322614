import { Input } from '@mantine/core'
import React, { useEffect, useRef, useState } from 'react'
import Network from '../helpers/Network'

type SearchProps = {
  title: string
  mountData: Function
  mountAmount: Function
  selected: any
  setSelected: React.Dispatch<React.SetStateAction<any>>
}

const Search: React.FC<SearchProps> = ({ title, mountData, selected, setSelected, mountAmount }) => {
  const dropdownRef = useRef(null)

  const [search, setSearch] = useState('')
  const [openDropdown, setOpenDropdown] = useState(true)
  const [searchData, setSearchData] = useState([])

  const getHandler = async (s: string) => {
    await Network.getData(`/players/search?name=${s}`).then((res) => {
      setSearchData(res)
      mountAmount()
    })
  }

  useEffect(() => {
    if (search?.length > 2) {
      setSelected({
        operator_player_name: '',
        id: '',
      })

      getHandler(search)
    } else {
      setSelected({
        operator_player_name: '',
        id: '',
      })

      setSearchData([])
    }
  }, [search])

  const handleClickOutside = (event: any) => {
    if (event.target && event.target !== dropdownRef.current) {
      setOpenDropdown(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  return (
    <div className='d-flex flex-column ms-5'>
      <span>{title}</span>
      <div className='position-relative'>
        <Input
          type='search'
          value={selected.operator_player_name ? selected.operator_player_name : search}
          onChange={(e) => {
            setSearch(e.target.value)
            if (search.length > 2) {
              setOpenDropdown(true)
            } else {
              mountData()
            }
          }}
          placeholder='Search Player'
        />
        {searchData.length > 0 && openDropdown ? (
          <div className='dropdown position-absolute bg-white w-100 shadow'>
            {searchData?.map((item: { operator_player_name: string; id: string }) => {
              return (
                <div
                  ref={dropdownRef}
                  className='p-2'
                  key={item.id}
                  onClick={() => {
                    setSelected(item)
                    setTimeout(() => {
                      setOpenDropdown(false)
                    }, 200)
                  }}
                >
                  {item?.operator_player_name}
                </div>
              )
            })}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default Search
