import React, {useEffect, useState} from 'react'
import Network from '../../../helpers/Network'
import { ServiceProps } from '../../../helpers/_models'

interface DataItems {
  player_id?: any
  game_id?: any
  key: string
  value: string
}
type ModalProps = {
  addGameConfigurationValue: DataItems
  setAddGameConfigurationValue: any
  type?: string
}

const GameConfigurationModal: React.FC<ModalProps> = ({
  addGameConfigurationValue,
  setAddGameConfigurationValue,
  type,
}) => {
  const [games, setGames] = useState<ServiceProps>()

  const mountProvider = async () => {
    await Network.getData('/games')
      .then((res) => {
        setGames(res)
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    mountProvider()
  }, [])

  return (
    <div className='d-flex flex-column align-items-center w-100 p-4'>
      <div className='position-relative w-100 m-4'>
        <label htmlFor='key' className='mb-1 ms-1'>
          Key
        </label>
        <input
          type='text'
          id='key'
          className='form-control form-control-sm form-control-solid'
          name='key'
          value={addGameConfigurationValue.key}
          onChange={(e) =>
            setAddGameConfigurationValue({
              ...addGameConfigurationValue,
              [e.target.name]: e.target.value,
            })
          }
          placeholder='Type Name'
        />
      </div>
      <div className='position-relative w-100 m-4'>
        <label htmlFor='value' className='mb-1 ms-1'>
          Value
        </label>
        <input
          type='text'
          id='value'
          className='form-control form-control-sm form-control-solid '
          name='value'
          value={addGameConfigurationValue.value}
          onChange={(e) =>
            setAddGameConfigurationValue({
              ...addGameConfigurationValue,
              [e.target.name]: e.target.value,
            })
          }
          placeholder='Type Name'
        />
      </div>

      <div className='position-relative w-100 m-4'>
        <label htmlFor='selectGame' className='mb-1 ms-1'>
          Select Game
        </label>
        <select
          className='form-select form-select-solid'
          id='selectGame'
          aria-label='Select'
          defaultValue={type === 'add' ? '0' : addGameConfigurationValue.game_id}
          onChange={(e) => {
            setAddGameConfigurationValue({
              ...addGameConfigurationValue,
              game_id: Number(e.target.value),
            })
          }}
        >
          <option style={{display: 'none'}} value='0' disabled>
            Select
          </option>
          {games?.data?.map((item: any) => {
            return (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            )
          })}
        </select>
      </div>
    </div>
  )
}

export default GameConfigurationModal
