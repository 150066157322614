/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import {CardsWidget17, CardsWidget20} from '../../../_metronic/partials/widgets'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import Network from '../../helpers/Network'
import {ListsWidget1} from './widgets/ListsWidget1'
import {TablesWidget1} from './widgets/TableWidget1'

const Dashboard: React.FC = () => {
  const [languages, setLanguages] = useState<any>({})
  const [currencies, setCurrencies] = useState<any>({})
  const [countries, setCountries] = useState<any>({})
  const [companies, setCompanies] = useState<any>({})
  const [games, setGames] = useState<any>({})
  const [players, setPlayers] = useState<any>({})

  const getAllLanguage = async () => {
    await Network.postData('/language/all', {
      order_key: 'name',
      order_by: 'asc',
    })
      .then((res) => {
        setLanguages(res)
      })
      .catch((err) => console.log(err))
  }
  const getAllCurrency = async () => {
    await Network.postData('/currency/all', {order_key: 'name', order_by: 'asc'})
      .then((res) => {
        setCurrencies(res)
      })
      .catch((err) => console.log(err))
  }
  const getAllCountry = async () => {
    await Network.postData('/country/all', {order_key: 'name', order_by: 'asc'})
      .then((res) => {
        setCountries(res)
      })
      .catch((err) => console.log(err))
  }
  const getAllCompany = async () => {
    await Network.postData('/company/all', {order_key: 'name', order_by: 'asc'})
      .then((res) => {
        setCompanies(res)
      })
      .catch((err) => console.log(err))
  }
  const getAllGames = async () => {
    await Network.postData('/games/all', {order_key: 'name', order_by: 'asc'})
      .then((res) => {
        setGames(res)
      })
      .catch((err) => console.log(err))
  }
  const getAllPlayers = async () => {
    await Network.postData('/players/all', {order_key: 'id', order_by: 'asc'})
      .then((res) => {
        setPlayers(res)
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    getAllLanguage()
    getAllCurrency()
    getAllCountry()
    getAllCompany()
    getAllGames()
    getAllPlayers()
  }, [])
  return (
    <>
      <div className='mb-10 p-2'>
        <>
          {/* begin::Row */}
          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            {/* begin::Col */}
            <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
              <CardsWidget20
                className='h-md-50 mb-5 mb-xl-10'
                description='Total Players'
                color='#F1416C'
                img={toAbsoluteUrl('/media/patterns/vector-1.png')}
                count={players?.total}
                pendingCount={players?.data?.filter((i: {ban: boolean}) => !i?.ban).length}
              />
              <CardsWidget17
                className='h-md-50 mb-5 mb-xl-10'
                totalCount={countries?.total}
                activeCount={
                  countries?.data?.filter((i: {status: string}) => i.status === 'active')?.length
                }
                passiveCount={
                  countries?.data?.filter((i: {status: string}) => i.status === 'passive')?.length
                }
                content='Country'
                chartId='country'
              />
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
              <CardsWidget17
                className='h-md-50 mb-5 mb-xl-10'
                totalCount={languages?.total}
                activeCount={
                  languages?.data?.filter((i: {status: string}) => i.status === 'active')?.length
                }
                passiveCount={
                  languages?.data?.filter((i: {status: string}) => i.status === 'passive')?.length
                }
                content='Language'
                chartId='language'
              />
              <CardsWidget17
                className='h-md-50 mb-5 mb-xl-10'
                totalCount={currencies?.total}
                activeCount={
                  currencies?.data?.filter((i: {status: string}) => i.status === 'active')?.length
                }
                passiveCount={
                  currencies?.data?.filter((i: {status: string}) => i.status === 'passive')?.length
                }
                content='Currency'
                chartId='currency'
              />
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className='col-xxl-6'>
              <TablesWidget1
                mountData={getAllGames}
                className='mb-5 mb-xl-8 h-md-100'
                data={games}
                setData={setGames}
                tablehead={['Name', 'Provider', 'Status']}
                addedButton={false}
              />
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}

          {/* begin::Row */}
          <div className='row gx-5 gx-xl-10'>
            {/* begin::Col */}
            <div className='col-xxl-6 mb-5 mb-xl-10'>
              <ListsWidget1
                className='h-md-100'
                title={'Companies'}
                list={companies}
                setList={setCompanies}
              />
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}
        </>
      </div>
    </>
  )
}
export {Dashboard}
