import React, {useRef} from 'react'
import {Pagination as MantinePagination, Select} from '@mantine/core'

type PaginationProps = {
  activePage: number
  setActivePage: React.Dispatch<React.SetStateAction<number>>
  countPerPage: number
  setCountPerPage: React.Dispatch<React.SetStateAction<number>>
  total: number
  perPageOptions?: number[]
  perPageShow?: boolean
}
const DEFAULT_PER_PAGE_OPTIONS = [10, 25, 50]

const Paginations: React.FC<PaginationProps> = ({
  activePage = '1',
  setActivePage,
  countPerPage = '10',
  setCountPerPage,
  total = '1',
  perPageOptions = DEFAULT_PER_PAGE_OPTIONS,
  perPageShow = true,
  ...rest
}) => {
  const ref = useRef<any>()

  const generateOptions = () => {
    return [...perPageOptions].map((i) => ({
      value: i.toString(),
      label: `${i} per page`,
    }))
  }
  return (
    <div className={`d-flex flex-row w-100  gap-10 ${perPageShow ? "justify-content-between" : "justify-content-center"}`}>
      {perPageShow && (
        <Select
          ref={ref}
          defaultValue={countPerPage.toString()}
          onChange={(e) => {
            setCountPerPage(Number(e))
            setActivePage(1)
          }}
          data={generateOptions()}
          styles={{
            root: {
              padding: '0px 8px',
            },
            input: {
              border: '0px',
              backgroundcolor: '#edf3f7',
              borderRadius: '8px',
            },
          }}
          rightSection={
            <svg
              version='1.1'
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              stroke='currentColor'
              strokeWidth={1}
              viewBox='0 0 24 24'
            >
              <path d='M12.529 14.676l4.288-4.288c0.135-0.136 0.219-0.323 0.219-0.529 0-0.414-0.335-0.749-0.749-0.75h-0c-0.001-0-0.002-0-0.004-0-0.205 0-0.391 0.083-0.526 0.218l-3.758 3.756-3.758-3.756c-0.136-0.136-0.323-0.22-0.53-0.22-0.414 0-0.749 0.335-0.749 0.749 0 0.207 0.084 0.394 0.219 0.529l4.287 4.288c0.136 0.136 0.323 0.219 0.53 0.219s0.394-0.084 0.53-0.219l-0 0z'></path>
            </svg>
          }
        />
      )}

      <MantinePagination
        radius='md'
        size='sm'
        withControls
        styles={{
          control: {
            borderColor: 'transparent',
            backgroundcolor: '#EDF3F7',
            fontWeight: 700,
            '&[data-disabled]': {
              backgroundcolor: '#EDF3F7',
            },
            '&[data-active]': {
              backgroundcolor: '#8674fa',
              color: 'white',
            },
            svg: {
              stroke: 'black',
              strokeWidth: 1,
            },
          },
        }}
        value={Number(activePage)}
        onChange={setActivePage}
        total={Number(total)}
        {...rest}
      />
    </div>
  )
}

export default Paginations
