/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import SearchPage from "./SearchPage"


const SearchProvider: FC = () => {

    return (
        <>
            {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle> */}
            <PageTitle breadcrumbs={[]}>Search</PageTitle>
            <SearchPage />
        </>
    )
}

export { SearchProvider }
