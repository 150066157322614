/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'

import { Dashboard } from './Dashboard'


const DashboardWrapper: FC = () => {

  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle> */}
      <PageTitle breadcrumbs={[]}>Homepage</PageTitle>
      <Dashboard />
    </>
  )
}

export { DashboardWrapper }
