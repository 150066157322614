/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='home-3'
        title='Homepage'
        // title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-briefcase-fill'
      />
      <SidebarMenuItem to='/players' icon='people' title='Players' fontIcon='duotone' />
      {/* <SidebarMenuItem to='/search' icon='shield-search' title='Search' fontIcon='duotone' /> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Actions</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/actions/company'
        title='Company Actions'
        fontIcon='bi-archive'
        icon='brifecase-tick'
      >
        <SidebarMenuItem to='/actions/company/list' title='Company List' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/actions/operator'
        title='Operator Actions'
        icon='abstract-31'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/actions/operator/list' title='Operator List' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/actions/provider'
        title='Provider Actions'
        icon='abstract-17'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/actions/provider/list' title='Provider List' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/actions/game'
        title='Game Actions'
        icon='abstract-27'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/actions/game/list' title='Game List' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/actions/service'
        title='Service Actions'
        icon='abstract-9'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/actions/service/countries' title='Countries' hasBullet={true} />
        <SidebarMenuItem to='/actions/service/currencies' title='Currencies' hasBullet={true} />
        <SidebarMenuItem to='/actions/service/languages' title='Languages' hasBullet={true} />
      </SidebarMenuItemWithSub>
      {/* <SidebarMenuItemWithSub
        to='/actions/integration'
        title='Integration Actions'
        icon='abstract-13'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/actions/integrations/list' title='Integration' hasBullet={true} />
      </SidebarMenuItemWithSub> */}

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Management</span>
        </div>
      </div>

      <SidebarMenuItemWithSub
        to='/management/reports'
        title='Reports'
        fontIcon='abstract-16'
        icon='abstract-16'
      >
        <SidebarMenuItem
          to='/management/reports/general'
          title='General Reports'
          hasBullet={true}
        />
        <SidebarMenuItem to='/management/reports/game' title='Game Reports' hasBullet={true} />
        <SidebarMenuItem to='/management/reports/operator' title='Operator Reports' hasBullet={true} />
        <SidebarMenuItem
          to='/management/reports/company'
          title='Company Reports'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/management/reports/provider'
          title='Provider Reports'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/management/management'
        icon='abstract-28'
        title='Management'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/management/management/user-list' title='User List' hasBullet={true} />
        <SidebarMenuItem to='/management/management/user-list' title='XXX' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/management/logs'
        icon='abstract-3'
        title='Logs'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/management/logs/user-logs' title='User Logs' hasBullet={true} />
        <SidebarMenuItem to='/management/logs/user-logs' title='XXX Logs' hasBullet={true} />
      </SidebarMenuItemWithSub>
    </>
  )
}

export {SidebarMenuMain}
