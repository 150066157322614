/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Input} from '@mantine/core'
import {useDebounce} from 'usehooks-ts'
import {KTIcon} from '../../../_metronic/helpers'
import Network from '../../helpers/Network'
import moment from 'moment'

const ReportLists: React.FC<Props> = ({
  className,
  data,
  calculated,
  setData,
  selectedPlayer,
  setSelectedPlayer,
  date,
  url,
  filters,
  setFilters,
}) => {
  console.log(filters)

  const [search, setSearch] = useState({
    amount_bet: '',
    amount_win: '',
  })
  const debouncedValue = useDebounce<object>(search, 500)
  const filterParams = async (url: any) => {
    try {
      const res = await Network.postData(url, {
        player_id: selectedPlayer.id ?? selectedPlayer.id,
        ...filters,
        ...search,
      })

      if (res) {
        setData(res)
      }
    } catch (error) {}
  }

  useEffect(() => {
    filterParams(url)
  }, [debouncedValue])
  return (
    <div className='card card-xl-stretch mb-5 mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bold text-dark'>General Reports ({data?.length})</h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-head pt-0 card-top-body'>
        {tableHead?.map(
          (item: {isSearchable: boolean; name: string; shortName: string}, index: number) => {
            return (
              <div className='p-2' key={index}>
                {item?.isSearchable ? (
                  <Input
                    type='number'
                    className=''
                    placeholder={item.name}
                    value={setSearch[item.shortName]}
                    onChange={(e) => {
                      const value = e.target.value
                      if (/^\d*\.?\d{0,1}$/.test(value)) {
                        setSearch({...search, [item.shortName]: value})
                      }
                    }}
                    icon={<KTIcon iconName='sort' iconType='duotone' className={`fs-3 `} />}
                  />
                ) : (
                  <span>{item.name}</span>
                )}
              </div>
            )
          }
        )}
      </div>
      <div className='card-body pt-0 card-top-body'>
        {data?.map((item: any, index: number) => {
          return (
            <div
              key={index}
              className={`px-2 ${
                item.isWinned ? 'bg-light-success' : 'bg-light-danger'
              } table-parent`}
              style={{marginBottom: '2px'}}
            >
              {/* begin::Icon */}
              <span
                className={`${
                  Number(item.amount_bet) < Number(item.amount_win)
                    ? 'text-danger'
                    : Number(item.amount_bet) === Number(item.amount_win)
                    ? 'bg-light-info'
                    : 'bg-light-danger'
                } me-5 d-flex align-items-center`}
              >
                <KTIcon
                  iconName='dollar'
                  className={`${item.isWinned ? 'text-danger' : 'bg-light-danger'} me-5 fs-1`}
                />
              </span>
              {/* end::Icon */}
              {/* begin::Title */}
              <div className='flex-grow-1 align-self-center me-2'>
                <div className='text-gray-800 text-hover-primary fs-6'>
                  {item.operator_relation?.name}
                </div>
              </div>
              {/* end::Title */}
              {/* begin::Title */}
              <div className='flex-grow-1 align-self-center me-2'>
                <span className='fw-semibold d-block'>
                  <span className=''>{item.player_relation?.operator_player_name}</span>
                </span>
              </div>
              {/* end::Title */}
              {/* begin::Title */}
              <div className='flex-grow-1 align-self-center me-2 d-flex flex-column align-items-start justify-content-center'>
                <div className='w-100 game-image'>
                  <img
                    src={
                      item.game_relation?.image === 'loremm'
                        ? 'https://hips.hearstapps.com/hmg-prod/images/legacy-fre-image-placeholder-1649872437.png'
                        : item.game_relation?.image
                    }
                    alt=''
                    height={30}
                    width={'100%'}
                    style={{objectFit: 'cover'}}
                  />
                  <span className='fw-bold text-gray-800 fs-6'>{item.game_relation?.name}</span>
                </div>

                {/* <span className='text-muted fw-semibold d-block'>
                  {item.game_relation.name}
                </span> */}
              </div>
              {/* end::Title */}
              {/* begin::Title */}
              <div className='flex-grow-1 align-self-center me-2 d-flex align-items-center'>
                <span className=' fw-semibold d-block ms-2'>
                  {item.amount_bet} {item.currency_relation?.symbol}
                </span>
              </div>
              {/* end::Title */}
              {/* begin::Title */}
              <div className='flex-grow-1 align-self-center me-2 d-flex align-items-center'>
                <span className=' fw-semibold d-block ms-2'>
                  {item.amount_win} {item.currency_relation?.symbol}
                </span>
              </div>
              {/* end::Title */}
              {/* begin::Lable */}
              {Number(item.amount_bet) < Number(item.amount_win) ? (
                <span className='flex-grow-1 align-self-center fw-bold d-flex  align-items-center justify-content-end text-success py-1'>
                  {'+'}
                  {100 -
                    Number(((Number(item.amount_bet) / Number(item.amount_win)) * 100).toFixed())}
                  {'%'}
                </span>
              ) : Number(item.amount_bet) > Number(item.amount_win) ? (
                <span className='flex-grow-1 align-self-center d-flex  align-items-center justify-content-end fw-bold text-danger py-1'>
                  {'-'}
                  {100 -
                    Number(((Number(item.amount_win) / Number(item.amount_bet)) * 100).toFixed())}
                  {'%'}
                </span>
              ) : (
                <span className='flex-grow-1 align-self-center d-flex  align-items-center justify-content-end fw-bold text-secondary py-1'>
                  {' '}
                  0{'%'}
                </span>
              )}

              {/* end::Lable */}
            </div>
          )
        })}
      </div>
      <div className='card-body d-flex justify-content-end me-4'>
        <div>
          {' '}
          <span className='fw-bold'>Total Bet:</span> {calculated?.totalBet}
        </div>
        <div className='ms-4'>
          <span className='fw-bold'>Total Win:</span> {calculated?.totalWin}
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export default ReportLists

type Props = {
  className?: string
  data?: any
  calculated?: any
  setData?: any
  selectedPlayer?: any
  setSelectedPlayer?: any
  date?: any
  url?: string
  filters?: any
  setFilters?: any
}

const tableHead = [
  {
    id: 0,
    name: '#',
    objectName: 'operator_relation',
    shortName: 'name',
    isSearchable: false,
  },
  {
    id: 1,
    name: 'Operator Name',
    objectName: 'operator_relation',
    shortName: 'name',
    isSearchable: false,
  },
  {
    id: 2,
    name: 'Player',
    objectName: 'player_relation',
    shortName: 'operator_player_name',
    isSearchable: false,
  },
  {
    id: 3,
    name: 'Game',
    objectName: 'game_relation',
    shortName: 'name',
    isSearchable: false,
  },
  {
    id: 4,
    name: 'Bet',
    objectName: '',
    shortName: 'amount_bet',
    isSearchable: true,
  },
  {
    id: 5,
    name: 'Win',
    objectName: '',
    shortName: 'amount_win',
    isSearchable: true,
  },
]
