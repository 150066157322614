import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {SearchProvider} from '../pages/search/SearchProvider'
import PlayerPageWrapper from '../pages/players/PlayerPageWrapper'
import PlayerDetailsWrapper from '../pages/players/detail/PlayerDetailsWrapper'
import CompanyReports from '../modules/reports/CompanyReports'
import ProviderReports from '../modules/reports/ProviderReports'
import OperatorReports from '../modules/reports/OperatorReports'

const PrivateRoutes = () => {
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const CompanyList = lazy(() => import('../modules/actions/company/CompanyList'))
  const ProviderList = lazy(() => import('../modules/actions/provider/ProviderList'))
  const GameList = lazy(() => import('../modules/actions/game/GameList'))
  const Countries = lazy(() => import('../modules/actions/service/Countries/Countries'))
  const Currencies = lazy(() => import('../modules/actions/service/Currencies/Currencies'))
  const Languages = lazy(() => import('../modules/actions/service/Languages/Languages'))
  const ReportList = lazy(() => import('../modules/management/reports/ReportList'))
  const UserList = lazy(() => import('../modules/management/management/UserList'))
  const UserLogs = lazy(() => import('../modules/management/logs/UserLogs'))
  const IntegrationList = lazy(() => import('../modules/actions/integrations/IntegrationList'))
  const OperatorList = lazy(() => import('../modules/actions/operator/OperatorList'))
  const GeneralReports = lazy(() => import('../modules/reports/GeneralReports'))
  const GameReports = lazy(() => import('../modules/reports/GameReports'))
  const Details = lazy(() => import('../patterns/Details'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route
          path='players'
          element={
            <SuspensedView>
              <PlayerPageWrapper />
            </SuspensedView>
          }
        />
        <Route path='players/detail/:id' element={<PlayerDetailsWrapper />} />
        <Route path='actions/operator/:id' element={<Details />} />
        <Route path='actions/games/:id' element={<Details />} />
        <Route path='search' element={<SearchProvider />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Details */}
        <Route
          path='details/:name/:id/*'
          element={
            <SuspensedView>
              <Details />
            </SuspensedView>
          }
        />
        {/* Lazy Modules Actions */}
        <Route
          path='actions/company/list/*'
          element={
            <SuspensedView>
              <CompanyList />
            </SuspensedView>
          }
        />
        {/* Operator Routes */}
        <Route
          path='actions/operator/list/*'
          element={
            <SuspensedView>
              <OperatorList />
            </SuspensedView>
          }
        />

        {/* Provider Routes */}
        <Route
          path='actions/provider/list/*'
          element={
            <SuspensedView>
              <ProviderList />
            </SuspensedView>
          }
        />
        {/* Game Routes */}
        <Route
          path='actions/game/list/*'
          element={
            <SuspensedView>
              <GameList />
            </SuspensedView>
          }
        />

        {/* Service Routes */}
        <Route
          path='actions/service/countries/*'
          element={
            <SuspensedView>
              <Countries />
            </SuspensedView>
          }
        />
        <Route
          path='actions/service/currencies/*'
          element={
            <SuspensedView>
              <Currencies />
            </SuspensedView>
          }
        />
        <Route
          path='actions/service/languages/*'
          element={
            <SuspensedView>
              <Languages />
            </SuspensedView>
          }
        />
        <Route
          path='actions/integrations/list/*'
          element={
            <SuspensedView>
              <IntegrationList />
            </SuspensedView>
          }
        />

        {/* Lazy Modules Management */}
        <Route
          path='management/reports/general/*'
          element={
            <SuspensedView>
              <GeneralReports />
            </SuspensedView>
          }
        />
        <Route
          path='management/reports/game/*'
          element={
            <SuspensedView>
              <GameReports />
            </SuspensedView>
          }
        />
        <Route
          path='management/reports/operator/*'
          element={
            <SuspensedView>
              <OperatorReports />
            </SuspensedView>
          }
        />
        <Route
          path='management/reports/company/*'
          element={
            <SuspensedView>
              <CompanyReports />
            </SuspensedView>
          }
        />
        <Route
          path='management/reports/provider/*'
          element={
            <SuspensedView>
              <ProviderReports />
            </SuspensedView>
          }
        />
        <Route
          path='management/management/user-list/*'
          element={
            <SuspensedView>
              <UserList />
            </SuspensedView>
          }
        />
        <Route
          path='management/logs/user-logs/*'
          element={
            <SuspensedView>
              <UserLogs />
            </SuspensedView>
          }
        />
        <Route
          path='management/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
