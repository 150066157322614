/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Network from '../../../helpers/Network'
import { MapProps, TableProps } from '../../../helpers/_models'
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Popover } from '@mantine/core'
import { DropdownStatus } from '../../../patterns/DropdownStatus'
import SkeletonComponent from '../../../patterns/SkeletonComponent'
import { AddModal, DeleteModal, EditModal } from '../Modals'
import GameConfigurationModal from '../widgets/GameConfigurationModal'

const TablesWidget: React.FC<TableProps> = ({
  className,
  data,
  setData,
  tablehead,
  mountData,
  addedButton = true,
  setCurrentGames,
  setIsOpen,
  setWindowTab,
  currentPlayer,
  setRequestBody,
  requestBody,
  activePage,
  countPerPage,
  setTotalData,
  setActivePage
}) => {
  const [filter, setFilter] = useState('0')
  const [addGameConfigurationValue, setAddGameConfigurationValue] = useState<any>({
    player_id: Number(currentPlayer.id),
    game_id: null,
    key: '',
    value: '',
  })
  const [openAddModal, setOpenAddModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [checkedList, setCheckedList] = useState<any[]>([])
  const [currentData, setCurrentData] = useState<object>({})
  const [batchProcess, setBatchProcess] = useState([
    {
      name: 'Delete Selected',
      icon: <KTIcon iconName='trash' iconType='duotone' className='fs-1' />,
      id: 'deleteSelected',
    },
    {
      name: 'Change Status',
      icon: <KTIcon iconName='shield' iconType='duotone' className='fs-1' />,
      id: 'changeStatus',
    },
  ])

  const addedGamesHandler = async () => {
    try {
      await Network.postData('/player-game-configurations', addGameConfigurationValue).then(
        (res) => {
          setOpenAddModal(false)
        }
      )
      await mountData()
    } catch (error) { }
  }
  const editGamesHandler = async (id: number) => {
    try {
      await Network.putData(`/player-game-configurations/${id}`, addGameConfigurationValue).then(
        (res) => {
          setOpenEditModal(false)
        }
      )
      await mountData()
    } catch (error) { }
  }
  const deletedGamesHandler = async (id: number) => {
    try {
      await Network.deleteData(`/player-game-configurations/${id}/game`).then((res) => {
        setOpenDeleteModal(false)
      })
      await mountData()
    } catch (error) { }
  }

  const batchProcessHandler = async (type: string, checkedList: any) => {
    setLoading(true)
    if (type === 'deleteSelected') {
      try {
        for (const item of checkedList) {
          await Network.deleteData(`/player-game-configurations/${item.id}/game`).then(
            async (res) => {
              await mountData()
              setCheckedList([])
            }
          )
        }
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    if (type === 'changeStatus') {
      try {
        for (const item of checkedList) {
          await Network.putData(`/games/${item.id}`, {
            status: item.status === 'active' ? 'passive' : 'active',
          }).then(async (res) => {
            await mountData()
            setCheckedList([])
          })
        }
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
  }
  const filterList = (type: string) => {
    if (requestBody.order_by === 'asc') {
      setRequestBody({
        order_key: type,
        order_by: 'desc',
      })
    } else {
      setRequestBody({
        order_key: type,
        order_by: 'asc',
      })
    }
  }

  const [mode, setMode] = useState(localStorage.getItem('kt_theme_mode_menu'))
  useEffect(() => {
    setMode(localStorage.getItem('kt_theme_mode_menu'))
  }, [mode])

  return (
    <div className={`card ${className}`} style={{ borderRadius: '0' }}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Games Configurations</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            {data?.total} Games Configurations
          </span>
        </h3>
        <div className='card-toolbar'>
          {checkedList.length > 0 && addedButton ? (
            <Popover width={300} position='left' withArrow shadow='md'>
              <Popover.Target>
                <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                  <KTIcon iconName='switch' className='fs-3' />
                </div>
              </Popover.Target>
              <Popover.Dropdown sx={{ padding: '0' }}>
                {batchProcess.map((item) => {
                  return (
                    <div
                      className='d-flex justify-content-start flex-column cursor-pointer border-bottom hover-bg-1'
                      id={item.id}
                      key={item.id}
                      onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                        batchProcessHandler(e.currentTarget.id, checkedList)
                      }
                    >
                      <div className='d-flex justify-content-start align-items-center p-2'>
                        <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                          {item.icon}
                        </span>
                        <span className='ms-2 fs-5 text'>{item.name}</span>
                      </div>
                    </div>
                  )
                })}
              </Popover.Dropdown>
            </Popover>
          ) : (
            ''
          )}
          {/* begin::Menu */}

          <button
            type='button'
            className='btn me-1 btn-sm btn-icon btn-color-primary btn-active-light-primary btn-bg-light'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <DropdownStatus
            setActivePage={setActivePage}
            setList={setData}
            url='/players'
            activePage={activePage}
            countPerPage={countPerPage}
            setTotalData={setTotalData}
            filter={filter}
            setFilter={setFilter}
          />
          {/* end::Menu */}
          {addedButton && (
            <span
              className='btn btn-sm btn-light-primary'
              onClick={() => {
                setOpenAddModal(true)
                setAddGameConfigurationValue({
                  player_id: Number(currentPlayer.id),
                  game_id: null,
                  key: '',
                  value: '',
                })
              }}
            >
              <KTIcon iconName='plus' className='fs-3' />
              New Configuration
            </span>
          )}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          {data?.length > 0 ? (
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid p-0 '>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                      checked={data.length === checkedList.length}
                      onChange={(e) => {
                        if (e.currentTarget.checked) {
                          setCheckedList(data)
                        } else {
                          setCheckedList([])
                        }
                      }}
                    />
                  </div>
                </th>
                {tablehead.map((item: MapProps['item'], index: number) => {
                  return (
                    <th
                      key={index}
                      className={`min-w-140px fw-bold ps-2 ${index === tablehead.length - 1 ? 'text-end' : ''
                        } `}
                    >
                      {item.name}
                      {item.isSort && (
                        <span
                          className='cursor-pointer'
                          onClick={() => {
                            filterList(item.sortName)
                          }}
                        >
                          <KTIcon
                            iconName='sort'
                            iconType='duotone'
                            className={`fs-3 ${requestBody.order_by === 'asc' ? 'rotate-360' : 'rotate-180'
                              }`}
                          />
                        </span>
                      )}
                    </th>
                  )
                })}
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              {!loading ? (
                <tbody>
                  {data?.map((item: any) => {
                    return (
                      <tr>
                        <td>
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input
                              className='form-check-input widget-9-check'
                              type='checkbox'
                              value='1'
                              checked={checkedList.some((i) => i.id === item.id)}
                              onChange={(e) => {
                                if (e.currentTarget.checked) {
                                  setCheckedList((prev) => [...prev, item])
                                } else {
                                  const newlist = checkedList.filter((i) => i.id !== item.id)
                                  setCheckedList(newlist)
                                }
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div
                              className='symbol symbol-45px me-5'
                              onClick={() => {
                                setCurrentGames(item)
                                setIsOpen(true)
                                setWindowTab('1')
                              }}
                            >
                              <img
                                src={toAbsoluteUrl(
                                  item.game_relation.image === 'loremm'
                                    ? 'https://hips.hearstapps.com/hmg-prod/images/legacy-fre-image-placeholder-1649872437.png'
                                    : item.game_relation.image
                                )}
                                alt=''
                              />
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className='text-dark fw-bold text-hover-primary fs-6'>
                                {item.game_relation.name}
                              </span>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {item.game_relation.short_name}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <span>{item.key}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <span>{item.value}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            <a
                              href='#'
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              onClick={() => {
                                setOpenEditModal(true)
                                setCurrentData(item)
                                setAddGameConfigurationValue({
                                  player_id: Number(item?.player_relation?.id),
                                  game_id: item.game_relation.id,
                                  key: item.key,
                                  value: item.value,
                                })
                              }}
                            >
                              <KTIcon iconName='pencil' iconType='duotone' className='fs-3' />
                            </a>
                            <a
                              href='#'
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                              onClick={() => {
                                setOpenDeleteModal(true)
                                setCurrentData(item)
                              }}
                            >
                              <KTIcon iconName='trash' iconType='duotone' className='fs-3' />
                            </a>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              ) : (
                <SkeletonComponent _count={data?.total} />
              )}

              {/* end::Table body */}
            </table>
          ) : (
            <div className='w-100'>Herhangi bir Data bulunmuyor</div>
          )}

          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
      {/* Modals */}
      {openAddModal && (
        <AddModal
          senderFunction={addedGamesHandler}
          title={'Added Configuration'}
          openModal={openAddModal}
          setOpenModal={setOpenAddModal}
          body={
            <GameConfigurationModal
              addGameConfigurationValue={addGameConfigurationValue}
              setAddGameConfigurationValue={setAddGameConfigurationValue}
              type={'add'}
            />
          }
        />
      )}
      {openDeleteModal && (
        <DeleteModal
          senderFunction={deletedGamesHandler}
          openModal={openDeleteModal}
          setOpenModal={setOpenDeleteModal}
          currentData={currentData}
        />
      )}
      {openEditModal && (
        <EditModal
          senderFunction={editGamesHandler}
          title={'Edit Configuration'}
          openModal={openEditModal}
          setOpenModal={setOpenEditModal}
          currentData={currentData}
          body={
            <GameConfigurationModal
              addGameConfigurationValue={addGameConfigurationValue}
              setAddGameConfigurationValue={setAddGameConfigurationValue}
            />
          }
        />
      )}
    </div>
  )
}

export { TablesWidget }
