import React, {useEffect, useState} from 'react'
import {StatisticsWidget1} from '../../../_metronic/partials/widgets'
import Network from '../../helpers/Network'
import {Button, Select} from '@mantine/core'
import DatePicker from 'react-datepicker'
import SvgData from '../../svgData'
import moment from 'moment'
import {OperatorReportList} from './Widgets/OperatorReportList'
import Search from '../../patterns/Search'
import ReportList from '../management/reports/ReportList'
import ReportLists from './ReportsLists'

type StateProps = {
  operator_id?: any
  currency_id?: any
  startDate?: any
  endDate?: any
  operator_player_name?: string
  id?: string
}
const {arrow, minus} = SvgData
const OperatorReports: React.FC = () => {
  const [serviceValues, setServiceValues] = useState<StateProps>({
    operator_id: undefined,
    currency_id: undefined,
    startDate: undefined,
    endDate: undefined,
  })
  const [selectedPlayer, setSelectedPlayer] = useState<StateProps>({
    operator_player_name: '',
    id: '',
  })
  const [calculated, setCalculated] = useState({
    totalBet: 0,
    totalWin: 0,
  })
  const [operatorList, setOperatorList] = useState([])
  const [currencyList, setCurrencyList] = useState([])
  const [reportList, setReportList] = useState([])

  const getAdminReportOperator = async () => {
    await Network.postData('admin/report/operator', {
      operator_id: serviceValues.operator_id,
      currency_id: serviceValues.currency_id,
      startDate: serviceValues.startDate && moment(serviceValues.startDate).utc().format(),
      endDate: serviceValues.endDate && moment(serviceValues.endDate).utc().format(),
      player_id: selectedPlayer.id || null,
    }).then((res) => {
      setReportList(res)
    })
  }

  const calculate = async () => {
    await Network.postData('/admin/calculated/reports/operator', {
      operator_id: serviceValues.operator_id,
      currency_id: serviceValues.currency_id,
      startDate: serviceValues.startDate && moment(serviceValues.startDate).utc().format(),
      endDate: serviceValues.endDate && moment(serviceValues.endDate).utc().format(),
      player_id: selectedPlayer.id || undefined,
    })
      .then((res) => setCalculated(res))
      .catch((error) => console.log(error))
  }

  const operatorListHandler = async () => {
    await Network.postData(`operator/all`, {
      order_key: 'id',
      order_by: 'asc',
    }).then((res) => {
      setOperatorList(res.data)
    })
  }
  const currencyListHandler = async () => {
    await Network.postData(`currency/all`, {
      order_key: 'id',
      order_by: 'asc',
    }).then((res) => {
      setCurrencyList(res.data)
    })
  }

  useEffect(() => {
    getAdminReportOperator()
    currencyListHandler()
    operatorListHandler()
    calculate()
  }, [])

  useEffect(() => {
    if (serviceValues.operator_id) {
      operatorListHandler()
    }
  }, [serviceValues.operator_id])

  return (
    <div>
      <div className='card p-2 my-2'>
        <div className='d-flex w-100 flex-row justify-content-between align-items-end'>
          <div className='breadcrumb d-flex align-items-end'>
            <div className='d-flex align-items-end ms-2'>
              <div>
                <span className='p-3'>Select Operator</span>
                <Select
                  onChange={(e) => {
                    setServiceValues({...serviceValues, operator_id: e, currency_id: null})
                  }}
                  defaultValue='Select'
                  data={operatorList?.map((item: any) => {
                    return {value: item.id, label: item.name}
                  })}
                  placeholder='Select Provider'
                  styles={{
                    root: {
                      padding: '0px 8px',
                    },
                  }}
                  rightSection={arrow(serviceValues.operator_id && 'rotate(-90deg)')}
                />
              </div>
            </div>

            {serviceValues.operator_id && operatorList.length > 0 ? (
              <div>
                <span className='p-3'>Select Currency</span>
                <Select
                  onChange={(e) => {
                    setServiceValues({...serviceValues, currency_id: e})
                  }}
                  placeholder='Select Game'
                  data={currencyList.map((item: any) => {
                    return {value: item.id, label: item.name}
                  })}
                  styles={{
                    root: {
                      padding: '0px 8px',
                    },
                  }}
                  rightSection={arrow(serviceValues.currency_id && 'rotate(-90deg)')}
                />
              </div>
            ) : (
              ''
            )}
            {serviceValues.operator_id && (
              <div className='d-flex align-items-end ms-2'>
                <div className='d-flex flex-column'>
                  <span>Select Date</span>
                  <DatePicker
                    showIcon
                    startDate={serviceValues.startDate}
                    endDate={serviceValues.endDate}
                    onChange={(date: any) =>
                      setServiceValues({
                        ...serviceValues,
                        startDate: date[0],
                        endDate: date[1],
                      })
                    }
                    className='p-1 cursor-pointer border border-secondary date-picker-input'
                    style={{borderRadius: '6px'}}
                    placeholderText={'Please select a date'}
                    dateFormat='dd.MM.yyyy'
                    withPortal
                    selectsRange={true}
                  />
                </div>
              </div>
            )}
            {serviceValues.operator_id && (
              <Search
                mountAmount={calculate}
                selected={selectedPlayer}
                setSelected={setSelectedPlayer}
                title='Search Player'
                mountData={getAdminReportOperator}
              />
            )}
          </div>
          <div>
            {serviceValues.operator_id && (
              <Button
                onClick={() => {
                  getAdminReportOperator()
                  calculate()
                }}
              >
                {' '}
                Filter{' '}
              </Button>
            )}
          </div>
        </div>
        {/* Filtre Listesi */}
        {/* <div className='p-4'>
          <p>Filters:</p>
          <div>
            <span className='px-4 py-1 bg-success text-white me-2'>
              {serviceValues.operator_id}
            </span>
            {serviceValues.game_id && (
              <span className='px-4 py-1 bg-success text-white me-2'>{serviceValues?.game_id}</span>
            )}
          </div>
        </div> */}
      </div>

      {/* <OperatorReportList calculated={calculated} className={''} data={reportList} /> */}
      <ReportLists
        calculated={calculated}
        className={''}
        data={reportList}
        setData={setReportList}
        selectedPlayer={selectedPlayer}
        setSelectedPlayer={setSelectedPlayer}
        date={undefined}
        url={'/admin/report/operator'}
        filters={{
          operator_id: serviceValues.operator_id ?? serviceValues.operator_id,
          currency_id: serviceValues.currency_id ?? serviceValues.currency_id,
          startDate: serviceValues.startDate ?? moment(serviceValues.startDate).utc().format(),
          endDate: serviceValues.endDate ?? moment(serviceValues.endDate).utc().format(),
        }}
        setFilters={setServiceValues}
      />
    </div>
  )
}

export default OperatorReports
