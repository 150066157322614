import React from "react";
import { Skeleton } from "@mantine/core";

type Props = {
    _count: number;
};

const SkeletonComponent: React.FC<Props> = ({ _count = 5 }) => {
    return (
        <tbody>
            {Array(_count).fill("").map((_: string, i: number) => (
                <tr key={i} >
                    <td colSpan={1}> <Skeleton height={28}  ml={10} mb={30} /></td>
                    <td colSpan={3}> <Skeleton height={28}  ml={10} mb={30} /></td>
                    <td colSpan={1}> <Skeleton height={28}  ml={10} mb={30} /></td>
                </tr>
            ))}
        </tbody>
    );
};

export default SkeletonComponent;