import React, {useEffect, useState} from 'react'
import PlayerDetails from './PlayerDetails'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useLocation} from 'react-router-dom'
import Network from '../../../helpers/Network'

const PlayerDetailsWrapper: React.FC = () => {
  const [id, setId] = useState('')
  const location = useLocation()
  const [currentPlayer, setCurrentPlayer] = useState({})
  const [windowTab, setWindowTab] = useState('1')
  const tmp = location.pathname.slice(
    location.pathname.lastIndexOf('/') + 1,
    location.pathname.length
  )

  useEffect(() => {
    setId(tmp)
  }, [])
  const playerDetailHandler = async () => {
    try {
      const res = await Network.getData(`players/${id}/detail`)
      setCurrentPlayer(res)
    } catch (error) {}
  }
  useEffect(() => {
    if (id) {
      playerDetailHandler()
    }
  }, [id])

  return (
    <>
      <PageTitle breadcrumbs={[{title: 'Players', path: '/players', isActive: true}]}>
        Player Details
      </PageTitle>
      <PlayerDetails
        currentPlayer={currentPlayer}
        setWindowTab={setWindowTab}
        windowTab={windowTab}
        playerDetailHandler={playerDetailHandler}
      />
    </>
  )
}

export default PlayerDetailsWrapper
