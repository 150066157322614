import React from 'react'
import {Modal} from '@mantine/core'

interface AddedModal {
  title: string
  senderFunction: any
  openModal: any
  setOpenModal: any
  body: React.ReactNode
  inputTypes?: string
}
interface UpdateModal {
  title: string
  senderFunction: any
  openModal: any
  setOpenModal: any
  currentData: any
  body: React.ReactNode
  inputTypes?: string
  banPlayers?: any
}
interface DeletedModal {
  senderFunction: any
  openModal: any
  setOpenModal: any
  currentData: any
}

const AddModal: React.FC<AddedModal> = ({title, senderFunction, openModal, setOpenModal, body}) => {
  const mode = localStorage.getItem('kt_theme_mode_menu')
  return (
    <>
      <Modal
        opened={openModal}
        onClose={function (): void {
          setOpenModal(false)
        }}
        title={title}
        size={650}
        styles={{
          header: {
            borderBottom: '2px solid #131313',
            backgroundColor: mode === 'dark' ? '#131313 !important' : 'white !important',
          },
          body: {
            backgroundColor: mode === 'dark' ? '#131313 !important' : 'white !important',
            color: mode === 'dark' ? 'white !important' : '#131313 !important',
          },
          root: {
            backgroundColor: 'transparent',
          },
        }}
        sx={{backgroundColor: mode === 'dark' ? '#131313 !important' : 'white !important'}}
        overlayProps={
          {
            // color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
            // opacity: 0.55,
            // blur: 3,
          }
        }
      >
        {body}
        <div className='modal-footer my-4'>
          <button type='button' className='btn btn-light me-4' onClick={() => setOpenModal(false)}>
            Close
          </button>
          <button type='button' className='btn btn-primary' onClick={() => senderFunction()}>
            Save
          </button>
        </div>
      </Modal>
    </>
  )
}
const EditModal: React.FC<UpdateModal> = ({
  title,
  senderFunction,
  openModal,
  setOpenModal,
  currentData,
  body,
  banPlayers,
}) => {
  const mode = localStorage.getItem('kt_theme_mode_menu')
  return (
    <>
      <Modal
        opened={openModal}
        onClose={function (): void {
          setOpenModal(false)
        }}
        title={title}
        size={650}
        styles={{
          header: {
            borderBottom: '2px solid #131313',
            backgroundColor: mode === 'dark' ? '#131313 !important' : 'white !important',
          },
          body: {
            backgroundColor: mode === 'dark' ? '#131313 !important' : 'white !important',
            color: mode === 'dark' ? 'white !important' : '#131313 !important',
          },
          root: {
            backgroundColor: 'transparent',
          },
        }}
        sx={{backgroundColor: mode === 'dark' ? '#131313 !important' : 'white !important'}}
      >
        {body}
        <div className='modal-footer my-4'>
          <button type='button' className='btn btn-light me-4' onClick={() => setOpenModal(false)}>
            Close
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => {
              if (banPlayers) {
                banPlayers(currentData.id)
              } else {
                senderFunction(currentData.id)
              }
            }}
          >
            Save
          </button>
        </div>
      </Modal>
    </>
  )
}
const DeleteModal: React.FC<DeletedModal> = ({
  currentData,
  senderFunction,
  openModal,
  setOpenModal,
}) => {
  const mode = localStorage.getItem('kt_theme_mode_menu')
  return (
    <div className='card'>
      <Modal
        opened={openModal}
        onClose={function (): void {
          setOpenModal(false)
        }}
        size={650}
        styles={{
          header: {
            borderBottom: '2px solid #131313',
            backgroundColor: mode === 'dark' ? '#131313 !important' : 'white !important',
          },
          body: {
            backgroundColor: mode === 'dark' ? '#131313 !important' : 'white !important',
            color: mode === 'dark' ? 'white !important' : '#131313 !important',
          },
          root: {
            backgroundColor: 'transparent',
          },
        }}
        sx={{backgroundColor: mode === 'dark' ? '#131313 !important' : 'white !important'}}
        withCloseButton={false}
      >
        <div className='d-flex flex-column align-items-center w-100 p-4'>
          <p className='h2 text-center'>
            Are you sure you want to delete the language named{' '}
            <span className='text-primary'>
              {currentData.name || currentData.operator_player_name}
            </span>
            ?
          </p>
        </div>
        <div className='modal-footer  justify-content-center my-4'>
          <button type='button' className='btn btn-light me-4' onClick={() => setOpenModal(false)}>
            No
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => senderFunction(currentData.id)}
          >
            Yes
          </button>
        </div>
      </Modal>
    </div>
  )
}
const AssociateModal: React.FC<UpdateModal> = ({
  title,
  senderFunction,
  openModal,
  setOpenModal,
  currentData,
  body,
}) => {
  const mode = localStorage.getItem('kt_theme_mode_menu')
  return (
    <>
      <Modal
        opened={openModal}
        onClose={function (): void {
          setOpenModal(false)
        }}
        title={title}
        size={650}
        styles={{
          header: {
            borderBottom: '2px solid #131313',
            backgroundColor: mode === 'dark' ? '#131313 !important' : 'white !important',
          },
          body: {
            backgroundColor: mode === 'dark' ? '#131313 !important' : 'white !important',
            color: mode === 'dark' ? 'white !important' : '#131313 !important',
          },
          root: {
            backgroundColor: 'transparent',
          },
        }}
        sx={{backgroundColor: mode === 'dark' ? '#131313 !important' : 'white !important'}}
      >
        {body}
        <div className='modal-footer my-4'>
          <button type='button' className='btn btn-light me-4' onClick={() => setOpenModal(false)}>
            Close
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => senderFunction(currentData.id)}
          >
            Save
          </button>
        </div>
      </Modal>
    </>
  )
}

export {AddModal, DeleteModal, EditModal, AssociateModal}
