/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { KTIcon } from '../../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'
import { DropdownStatus } from '../../../patterns/DropdownStatus'

type Props = {
  className: string
  title: string
  list: any
  setList: any
}

const ListsWidget1: React.FC<Props> = ({ className, title, list, setList }) => {
  const navigate = useNavigate()
  const [filter, setFilter] = useState('0')
  return (
    <div
      className={`card card-xl-stretch mb-xl-8 ${className}`}
      style={{ marginBottom: '0px !important', height: '100% !important' }}
    >
      {/* begin::Header */}
      <div className='card-header align-items-center border-0 mt-4 '>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold text-dark'>{title}</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Articles and publications</span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <DropdownStatus
            filter={filter}
            setFilter={setFilter} setList={setList} url={"/company"} />
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-3'>
        {/* begin::Item */}
        {list?.data?.map((item: any, index: number) => {
          if (index < 4) {
            return (
              <div className='d-flex align-items-sm-center mb-7' key={index}>
                {/* begin::Symbol */}
                {/* <div className='symbol symbol-60px symbol-2by3 me-4'>
                        <div
                          className='symbol-label'
                          style={{ backgroundImage: `url(${toAbsoluteUrl(`/media/stock/600x400/img-${index + 1}.jpg`)})` }}
                        ></div>
                      </div> */}
                {/* end::Symbol */}
                {/* begin::Title */}
                <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
                  <div className='flex-grow-1 me-2'>
                    <a href='#' className='text-gray-800 fw-bold text-hover-primary fs-6'>
                      {item.name}
                    </a>
                  </div>
                  {item.status === 'active' ? (
                    <span className='badge badge-light-success fs-8 fw-bold my-2'>Active</span>
                  ) : (
                    <span className='badge badge-light-danger fs-8 fw-bold my-2'>Passive</span>
                  )}
                </div>
                {/* end::Title */}
              </div>
            )
          }
        })}

        {list.total >= 4 && (
          <div className='d-flex justify-content-center'>
            <button
              className='btn btn-primary'
              onClick={() => {
                navigate('/actions/company/list')
              }}
            >
              See All Company
            </button>
          </div>
        )}

        {/* end::Item */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export { ListsWidget1 }
