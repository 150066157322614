import React, { useEffect, useState } from 'react'
import { TablesWidget } from './widgets/TableWidget'
import Network from '../../helpers/Network'
const PlayerPage: React.FC = () => {
  const [filter, setFilter] = useState('0')
  const [players, setPlayers] = useState([])
  const [activePage, setActivePage] = useState(1)
  const [countPerPage, setCountPerPage] = useState(10)
  const [totalData, setTotalData] = useState<any>(null)
  const [requestBody, setRequestBody] = useState({
    order_key: 'id',
    order_by: 'asc',
  })
  console.log(requestBody)

  const mount = async () => {
    try {
      const res = await Network.postData(
        `/players/all?page=${activePage}&take=${countPerPage}`,
        requestBody
      )
      setPlayers(res.data)
      setTotalData(res.total)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    mount()
  }, [])
  useEffect(() => {
    const reMount = async () => {
      const filterUrl = filter === "1" ? "false" : "true"
      const statuURI = filter !== "0" ? `&ban=${filterUrl}` : ""
      try {
        const res = await Network.postData(
          `/players/all?page=${activePage}&take=${countPerPage}${statuURI}`,
          requestBody
        )
        setPlayers(res.data)
        setTotalData(res.total)
      } catch (error) {
        console.log(error)
      }
    }
    reMount()
  }, [countPerPage, activePage, requestBody])

  return (
    <div className='h-100'>
      <TablesWidget
        mountData={mount}
        className='mb-5 mb-xl-8'
        data={players}
        setData={setPlayers}
        tablehead={[
          { name: 'Player Name', isSort: true, sortName: 'name' },
          { name: 'Ban Status', isSort: false, sortName: '' },
          { name: 'Actions', isSort: false, sortName: '' },
        ]}
        setActivePage={setActivePage}
        setCountPerPage={setCountPerPage}
        activePage={activePage}
        countPerPage={countPerPage}
        totalData={totalData}
        requestBody={requestBody}
        setRequestBody={setRequestBody}
        setTotalData={setTotalData}
        filter={filter}
        setFilter={setFilter}
      />
    </div>
  )
}

export { PlayerPage }
