import React from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { TablesWidget } from './TableWidget'

type ConfigurationProps = {
  tabData: any
  currentPlayer: any
  mountData: any
}

const GameConfiguration: React.FC<ConfigurationProps> = ({ tabData, currentPlayer, mountData }) => {
  return (
    <TablesWidget
      className={''}
      tablehead={['Game Name', 'Key', 'Value', 'Actions']}
      mountData={mountData}
      data={tabData}
      currentPlayer={currentPlayer}

    />
  )
}

export default GameConfiguration
