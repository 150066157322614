/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Network from '../../../helpers/Network'
import { Input, Popover } from '@mantine/core'
import SkeletonComponent from '../../../patterns/SkeletonComponent'
import { AddModal, DeleteModal, EditModal } from '../Modals'
import { KTIcon } from '../../../../_metronic/helpers'
import { DropdownStatus } from '../../../patterns/DropdownStatus'
import { TableProps, MapProps } from '../../../helpers/_models'
import Paginations from '../../../patterns/Paginations'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from 'usehooks-ts'
import createUrl from '../../../helpers/createUrlFormatter'
import createFilter from '../../../helpers/createFilter'

const TablesWidget: React.FC<TableProps> = ({
  className,
  data,
  setData,
  tablehead,
  mountData,
  setCountPerPage,
  countPerPage,
  setActivePage,
  activePage,
  totalData,
  setRequestBody,
  requestBody,
  setTotalData,
  filter,
  setFilter,
  search,
  setSearch
}) => {
  const navigate = useNavigate()
  const [addCompanyValue, setAddCompanyValue] = useState({
    name: '',
    public_name: '',
    status: 'avtive',
  })


  // const { name, public_name } = search
  const debouncedValue = useDebounce<object>(search, 500)
  const [openAddModal, setOpenAddModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [checkedList, setCheckedList] = useState<any[]>([])
  const [currentData, setCurrentData] = useState<object>({})

  const [batchProcess, setBatchProcess] = useState([
    {
      name: 'Delete Selected',
      icon: <KTIcon iconName='trash' iconType='duotone' className='fs-1' />,
      id: 'deleteSelected',
    },
    {
      name: 'Change Status',
      icon: <KTIcon iconName='shield' iconType='duotone' className='fs-1' />,
      id: 'changeStatus',
    },
  ])
  const addedCompanyHandler = async () => {
    try {
      await Network.postData('/company', addCompanyValue).then((res) => {
        setOpenAddModal(false)
      })
      await mountData()
    } catch (error) { }
  }
  const editCountryHandler = async (id: number) => {
    try {
      await Network.putData(`/company/${id}`, addCompanyValue).then((res) => {
        setOpenEditModal(false)
      })
      await mountData()
    } catch (error) { }
  }
  const deletedCountryHandler = async (id: number) => {
    try {
      await Network.deleteData(`/company/${id}`).then((res) => {
        setOpenDeleteModal(false)
      })
      await mountData()
    } catch (error) { }
  }
  const batchProcessHandler = async (type: string, checkedList: any) => {
    setLoading(true)
    if (type === 'deleteSelected') {
      try {
        for (const item of checkedList) {
          await Network.deleteData(`/company/${item.id}`).then(async (res) => {
            await mountData()
            setCheckedList([])
          })
        }
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    if (type === 'changeStatus') {
      try {
        for (const item of checkedList) {
          await Network.putData(`/company/${item.id}`, {
            status: item.status === 'active' ? 'passive' : 'active',
          }).then(async (res) => {
            await mountData()
            setCheckedList([])
          })
        }
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
  }
  const filterList = (type: string) => {
    if (requestBody.order_by === 'asc') {
      setRequestBody({
        order_key: type,
        order_by: 'desc',
      })
    } else {
      setRequestBody({
        order_key: type,
        order_by: 'asc',
      })
    }
  }
  const filterParams = async (search: { name: string; public_name: string }) => {
    const { name, public_name } = search
    const status = createFilter(filter)
    const url = createUrl({
      name,
      public_name,
      page: activePage,
      take: countPerPage,
      status: status,
    })
    try {
      const res = await Network.getData(`/admin/search/companies${url ? url : '?name='}`)
      if (res) {
        setData(res.data)
        setTotalData(res.total)
      }
    } catch (error) { }
  }

  useEffect(() => {
    filterParams(search)
    if (search.name === "" && search.public_name === "") {
      setActivePage(1)
    }
    setActivePage(1)
  }, [debouncedValue])

  const [mode, setMode] = useState(localStorage.getItem('kt_theme_mode_menu'))
  useEffect(() => {
    setMode(localStorage.getItem('kt_theme_mode_menu'))
  }, [mode])

  return (
    <div className={`card ${className} h-100`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Companies</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{data.length} Companies</span>
        </h3>
        <div className='card-toolbar'>
          {checkedList.length > 0 && (
            <Popover width={300} position='left' withArrow shadow='md'>
              <Popover.Target>
                <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                  <KTIcon iconName='switch' className='fs-3' />
                </div>
              </Popover.Target>
              <Popover.Dropdown sx={{ padding: '0' }}>
                {batchProcess.map((item) => {
                  return (
                    <div
                      className='d-flex justify-content-start flex-column cursor-pointer border-bottom hover-bg-1'
                      id={item.id}
                      key={item.id}
                      onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                        batchProcessHandler(e.currentTarget.id, checkedList)
                      }
                    >
                      <div className='d-flex justify-content-start align-items-center p-2'>
                        <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                          {item.icon}
                        </span>
                        <span className='ms-2 fs-5 text'>{item.name}</span>
                      </div>
                    </div>
                  )
                })}
              </Popover.Dropdown>
            </Popover>
          )}
          {/* begin::Menu */}
          <button
            type='button'
            className='btn me-1 btn-sm btn-icon btn-color-primary btn-active-light-primary btn-bg-light'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <DropdownStatus
            setActivePage={setActivePage}
            setList={setData}
            url='/company'
            activePage={activePage}
            countPerPage={countPerPage}
            setTotalData={setTotalData}
            filter={filter}
            setFilter={setFilter}
            setSearch={setSearch}
            searchDefault={{
              name: '',
              public_name: '',
            }}
          />
          {/* end::Menu */}
          <span
            className='btn btn-sm btn-light-primary'
            onClick={() => {
              setOpenAddModal(true)
              setAddCompanyValue({ name: '', public_name: '', status: 'active' })
            }}
          >
            <KTIcon iconName='plus' className='fs-3' />
            New Company
          </span>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}

      <div className='card-body py-3 d-flex flex-column justify-content-between'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}

          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                {/* <th className='w-25px'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='1'
                        data-kt-check='true'
                        data-kt-check-target='.widget-9-check'
                        checked={data.length === checkedList.length}
                        onChange={(e) => {
                          if (e.currentTarget.checked) {
                            setCheckedList(data)
                          } else {
                            setCheckedList([])
                          }
                        }}
                      />
                    </div>
                  </th> */}
                {tablehead.map((item: MapProps['item'], index: MapProps['index']) => {
                  console.log(item)
                  return (
                    <th
                      key={index}
                      className={`min-w-140px ps-0 ${index === tablehead.length - 1 && 'text-end'}`}
                      style={{ paddingBottom: item.isSearchable ? '8px' : '' }}
                    >
                      {item.isSearchable && item.isSort ? (
                        <span
                          className='cursor-pointer position-absolute'
                          onClick={() => {
                            filterList(item.sortName)
                          }}
                        >
                          <KTIcon
                            iconName='sort'
                            iconType='duotone'
                            className={`fs-3 ${requestBody.order_by === 'asc' ? 'rotate-360' : 'rotate-180'
                              }`}
                          />
                        </span>
                      ) : (
                        ''
                      )}
                      {item.isSearchable ? (
                        <Input
                          type='text'
                          className=''
                          placeholder={item.name}
                          value={search[item.sortName]}
                          onChange={(e) => {
                            setSearch({ ...search, [item.sortName]: e.target.value })
                          }}
                          icon={
                            item.isSort && (
                              <KTIcon
                                iconName='sort'
                                iconType='duotone'
                                handleClick={() => {
                                  filterList(item.sortName)
                                }}
                                className={`fs-3 ${requestBody.order_by === 'asc' ? 'rotate-360' : 'rotate-180'
                                  }`}
                              />
                            )
                          }
                        />
                      ) : (
                        item.name
                      )}
                    </th>
                  )
                })}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}

            {!loading ? (
              <tbody>
                {data
                  // .filter((item: { isDelete: boolean }) => !item.isDelete)
                  .map((item: any) => {
                    return (
                      <tr key={item?.id}>
                        {/* <td className='p-0'>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                              <input
                                className='form-check-input widget-9-check'
                                type='checkbox'
                                value='1'
                                checked={checkedList.some((i) => i.id === item.id)}
                                onChange={(e) => {
                                  if (e.currentTarget.checked) {
                                    setCheckedList((prev) => [...prev, item])
                                  } else {
                                    const newlist = checkedList.filter((i) => i.id !== item.id)
                                    setCheckedList(newlist)
                                  }
                                }}
                              />
                            </div>
                          </td> */}
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <a
                                href='#'
                                className='text-dark fw-bold text-hover-primary ps-2 fs-6'
                              >
                                {item.name}
                              </a>
                            </div>
                          </div>
                        </td>
                        <td className='p-0'>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <a
                                href='#'
                                className='text-dark fw-bold text-hover-primary ps-2 fs-6'
                              >
                                {item.public_name}
                              </a>
                            </div>
                          </div>
                        </td>
                        <td className='p-0'>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              {item.status === 'active' ? (
                                <span className=' btn-active-color-primary btn-sm me-1'>
                                  <KTIcon iconName='shield-tick' className='fs-3 text-success' />
                                </span>
                              ) : (
                                <span className=' btn-active-color-primary btn-sm me-1'>
                                  <KTIcon iconName='shield-cross' className='fs-3 text-danger' />
                                </span>
                              )}
                            </div>
                          </div>
                        </td>
                        <td className='p-0'>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            <span
                              className='btn btn-icon  btn-active-color-primary btn-sm'
                              onClick={() => {
                                navigate(`/details/company/${item.id}`, {
                                  state: { item },
                                })
                              }}
                            >
                              <KTIcon iconName='dots-square' className='fs-3' iconType='duotone' />
                            </span>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            ) : (
              <SkeletonComponent _count={data.length} />
            )}

            {/* end::Table body */}
          </table>

          {/* end::Table */}
        </div>
        {/* end::Table container */}
        {/* begin::Pagination*/}
        <div className='d-flex justify-content-end w-100'>
          {totalData && (
            <Paginations
              activePage={activePage}
              setActivePage={setActivePage}
              countPerPage={countPerPage}
              setCountPerPage={setCountPerPage}
              total={Math.ceil(totalData / countPerPage)}
            />
          )}
        </div>
        {/* end::Pagination*/}
      </div>

      {/* begin::Body */}

      {/* Modals */}
      {openAddModal && (
        <AddModal
          senderFunction={addedCompanyHandler}
          title={'Added Companies'}
          openModal={openAddModal}
          setOpenModal={setOpenAddModal}
          body={
            <div className='d-flex align-items-center w-100 p-4'>
              <div className='position-relative w-100 m-4'>
                <label htmlFor='countryName' className='mb-1 ms-1'>
                  Company Name
                </label>
                <input
                  type='text'
                  id='countryName'
                  className='form-control form-control-sm form-control-solid '
                  name='name'
                  value={addCompanyValue.name}
                  onChange={(e) =>
                    setAddCompanyValue({ ...addCompanyValue, [e.target.name]: e.target.value })
                  }
                  placeholder='Type Name'
                />
              </div>
              <div className='position-relative w-100 m-4'>
                <label htmlFor='publicName' className='mb-1 ms-1'>
                  Company Public Name
                </label>
                <input
                  type='text'
                  id='publicName'
                  className='form-control form-control-sm form-control-solid '
                  name='public_name'
                  value={addCompanyValue.public_name}
                  onChange={(e) =>
                    setAddCompanyValue({ ...addCompanyValue, [e.target.name]: e.target.value })
                  }
                  placeholder='Type Public Name'
                />
              </div>
            </div>
          }
        />
      )}
      {openDeleteModal && (
        <DeleteModal
          senderFunction={deletedCountryHandler}
          openModal={openDeleteModal}
          setOpenModal={setOpenDeleteModal}
          currentData={currentData}
        />
      )}
      {openEditModal && (
        <EditModal
          senderFunction={editCountryHandler}
          title={'Edit Company'}
          openModal={openEditModal}
          setOpenModal={setOpenEditModal}
          currentData={currentData}
          body={
            <div className='d-flex align-items-center w-100 p-4'>
              <div className='position-relative w-100 m-4'>
                <label htmlFor='countryName' className='mb-1 ms-1'>
                  Company Name
                </label>
                <input
                  type='text'
                  id='countryName'
                  className='form-control form-control-sm form-control-solid '
                  name='name'
                  value={addCompanyValue.name}
                  onChange={(e) =>
                    setAddCompanyValue({ ...addCompanyValue, [e.target.name]: e.target.value })
                  }
                  placeholder='Type Name'
                />
              </div>
              <div className='position-relative w-100 m-4'>
                <label htmlFor='shortCode' className='mb-1 ms-1'>
                  Company Short Code
                </label>
                <input
                  type='text'
                  id='shortCode'
                  className='form-control form-control-sm form-control-solid '
                  name='public_name'
                  value={addCompanyValue.public_name}
                  onChange={(e) =>
                    setAddCompanyValue({ ...addCompanyValue, [e.target.name]: e.target.value })
                  }
                  placeholder='Type Short Code'
                />
              </div>
            </div>
          }
        />
      )}
    </div>
  )
}

export default TablesWidget
