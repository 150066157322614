import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { PlayerPage } from './PlayerPage'

const PlayerPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Search</PageTitle>
      <PlayerPage />
    </>
  )
}

export default PlayerPageWrapper
